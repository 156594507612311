<template>
  <div>
    <p class="">
      <span class="text-capitalize h4">hello {{ clientInfo.firstname }},</span>
      <span class=""> This is what we've got for you.</span>
    </p>
    <template v-if="fetchingData === false">
      <b-row>
        <b-col
          lg="8"
          md="12"
        >
          <b-card
            no-body
            class="p-0"
          >
            <b-card-body
              class="pb-0"
            >
              <b-card-header
                class="p-0"
              >
                <b-card-title>Upcoming Schedule</b-card-title>
                <b-button
                  variant="outline-primary"
                  class="float-right"
                  @click="goToCalendar()"
                >
                  <span class="align-middle">View in Calendar</span>
                </b-button>
              </b-card-header>
            </b-card-body>
            <b-list-group
              v-if="clientInfo.upcShifts"
              flush
              class="border-bottom mt-1 upcomingschedlist"
            >
              <b-list-group-item
                v-for="(shift,k) in clientInfo.upcShifts"
                :key="k"
                class="list-inline"
              >
                <b-media
                  vertical-align="center"
                  right-align
                >
                  <template #aside>
                    <b-badge
                      v-if="shift.status == '6'"
                      pill
                      class="openshift_badge"
                    >
                      Open Shift
                    </b-badge>
                    <b-badge
                      v-if="shift.status == '1'"
                      pill
                      class="scheduled_badge"
                    >
                      Scheduled
                    </b-badge>
                    <b-avatar
                      v-if="shift.caregiver != 0"
                      :src="shift.caregiverPicture ? `${url}upload/${shift.caregiver}/${shift.caregiverPicture}` : ''"
                      variant="primary"
                      width="32"
                      :text="shift.caregiver_firstname ? avatarText(`${shift.caregiver_firstname.slice(0,1)} ${shift.caregiver_lastname.slice(0,1)}`) : ''"
                      rounded
                    />
                  </template>
                  <b-avatar
                    rounded
                    variant="light-primary"
                    size="34"
                    class="d-inline-block align-self-center"
                  >
                    <feather-icon
                      icon="CalendarIcon"
                      size="18"
                    />
                  </b-avatar>
                  <div
                    class="ml-2"
                  >
                    <h5 class="media-heading mb-0 textual">
                      {{ shift.textual }}
                    </h5>
                    <b-card-text class="mb-0 textual_time">
                      {{ shift.start_time }} - {{ shift.end_time }}
                    </b-card-text>
                  </div>
                </b-media>
              </b-list-group-item>
            <!-- <b-list-group-item>
              <div class="d-flex flex-row justify-content-center align-items-center">
                <h5>No records found</h5>
              </div>
            </b-list-group-item> -->
            </b-list-group>
            <b-list-group
              v-else
              flush
              class="border-bottom mt-1 upcomingschedlist h-50"
            >
              <b-list-group-item
                class="list-inline text-center"
              >
                No Upcoming Schedule
              </b-list-group-item>
            </b-list-group>
          </b-card>

          <b-card
            v-if="clientInfo.todays_photos"
            class="p-0"
          >
            <b-card-body
              class="p-0"
            >
              <div class="mb-2">
                <h4>Today's Photos</h4>
              </div>

              <!-- Swiper -->
              <swiper
                class="swiper-responsive-breakpoints px-0 py-2"
                :options="swiperOptions"
              >
                <swiper-slide
                  v-for="(item,index) in clientInfo.todays_photos"
                  :key="index"
                >
                  <div class="img-container w-75 mx-auto py-0">
                    <b-img
                      :src="`${url}api/mobile/tasks/${item}`"
                      fluid
                    />
                  </div>
                </swiper-slide>
                <div
                  slot="pagination"
                  class="swiper-pagination"
                />
              <!-- Add Arrows
              <div
                slot="button-next"
                class="swiper-button-next"
              />
              <div
                slot="button-prev"
                class="swiper-button-prev"
              /> -->
              </swiper>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          lg="4"
          md="12"
        >
          <b-card
            :img-src="require('@/assets/images/banner/banner-12.jpg')"
            img-top
            class="card-profile"
          >
            <div class="profile-image-wrapper">
              <div class="profile-image-client borderimg p-0">
                <b-avatar
                  size="114"
                  variant="primary"
                  square
                  :src="clientInfo.profile_picture ? `${clientInfo.profile_picture}` : ''"
                  :text="avatarText(`${clientInfo.firstname.slice(0,1)} ${clientInfo.lastname.slice(0,1)}`)"
                />
              </div>
            </div>
            <h3 class="mt-1">{{ clientInfo.clientName }}
              <feather-icon
                v-b-tooltip.hover.top="'Edit Client Profile'"
                icon="EditIcon"
                size="18"
                class="cursor-pointer gray-1"
                @click="goToEditProfile()"
              />
            </h3>
            <b-col
              cols="12"
              class="p-0 mt-2"
            >
              <table class="mt-2 mt-xl-0 w-100 text-left cdashwidget">
                <!-- <tr>
                <th
                  class="pb-50"
                  style="width: 35%;"
                >
                  <template>
                    <img
                      src="@/assets/images/icons/custom-icons/id_icon.svg"
                      class="ac-icons mr-75"
                    >
                  </template>
                  <span class="font-weight-bolder">SSN:</span>
                </th>
                <td class="pb-50">
                  {{ clientInfo.sss }}
                </td>
              </tr> -->
                <tr>
                  <th class="pb-50 gray-1">
                    <feather-icon
                      icon="HomeIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bolder">Address:</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ clientInfo.addressLine1 }} {{ clientInfo.state }} {{ clientInfo.postalCode }}
                  </td>
                </tr>
                <tr>
                  <th class="gray-1">
                    <feather-icon
                      icon="PhoneIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bolder">Contact:</span>
                  </th>
                  <td>
                    {{ clientInfo.homePhone }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50 gray-1">
                    <feather-icon
                      icon="MapPinIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bolder">Location:</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ clientInfo.location }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50 gray-1">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bolder">Age:</span>
                  </th>
                  <td class="pb-50">
                    {{ clientInfo.age || '--' }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50 gray-1">
                    <feather-icon
                      icon="KeyIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bolder">Referrer:</span>
                  </th>
                  <td class="pb-50">
                    {{ clientInfo.referrer || '--' }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-card>

          <b-card
            v-if="clientInfo.active_caregivers"
            no-body
          >
            <b-card-header>
              <b-card-title>My Caregivers</b-card-title>
            </b-card-header>
            <b-card-body>
              <b-list-group
                v-for="(item,index) in clientInfo.active_caregivers"
                :key="index"
              >
                <b-list-group-item
                  class="px-0 border-0"
                >
                  <div class="d-flex flex-row">
                    <div class="px-0">
                      <b-avatar
                        :src="item.profilepicture ? `${url}upload/${item.id}/${item.profilepicture}` : ''"
                        width="42"
                        height="42"
                        variant="primary"
                        rounded
                        :text="item.firstname ? avatarText(`${item.firstname.slice(0,1)} ${item.lastname.slice(0,1)}`) : ''"
                        class="img-fluid"
                      />
                    </div>
                    <div>
                      <div class="text-14 pl-2 black font-weight-bold">
                        {{ item.firstname }} {{ item.lastname }}
                      </div>
                      <div
                        v-b-toggle.sidebar-view-profile-caregiver
                        class="color-3 text-12 pl-2 cursor-pointer"
                        @click="viewCaregiver(item.id)"
                      >
                        View Profile
                      </div>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-button v-b-toggle.sidebar-view-profile-caregiver>
      Toggle Sidebar
    </b-button> -->
      <div
        v-if="clientInfo.active_caregivers.length > 0"
        class="body-content-overlay"
      />
      <caregiver-side-profile v-if="clientInfo.active_caregivers.length > 0" />
    </template>
    <template v-else>
      <div class="text-center pt-5">
        <b-spinner
          style="width: 3rem; height: 3rem;"
          type="grow"
        />
        <strong class="d-block mt-2">Loading Information...</strong>
      </div>
    </template>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Vue from 'vue'
import store from '@/store'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BListGroup,
  BListGroupItem,
  BMedia,
  BAvatar,
  BImg,
  BButton,
  BBadge,
  BSpinner,
  VBTooltip,
  VBToggle,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import CaregiverSideProfile from './CaregiverSideProfile'
import clientDashboardStoreModule from './clientDashboardStoreModule.js'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BMedia,
    BAvatar,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BImg,
    BButton,
    BSpinner,
    Swiper,
    SwiperSlide,
    BBadge,
    CaregiverSideProfile,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  setup() {
    const CLIENT_DASHBOARD_APP_STORE_MODULE_NAME = 'client_dashboard'

    // Register module
    if (!store.hasModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)) {
      store.registerModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME, clientDashboardStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)
      }
    })

    /* const isSideBarOpen = ref(false)
    const toggleSidebar = () => {
      if (isSideBarOpen.value) {
        isSideBarOpen.value = false
      } else {
        isSideBarOpen.value = true
      }
    } */

    const swiperOptions = {
      slidesPerView: 3,
      spaceBetween: 55,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      /* navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }, */
      centerInsufficientSlides: true,
      breakpoints: {
        1600: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        1300: {
          slidesPerView: 3,
          spaceBetween: 55,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 55,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 55,
        },
      },
    }

    /* eslint-disable global-require */

    const uDATA = JSON.parse(localStorage.getItem('userData'))
    const clientInfo = ref(computed(() => store.state.client_dashboard.clientInfo))
    const fetchingData = ref(true)
    // *===============================================---*
    // *--------- RETRIEVE DATA ---------------------------*
    // *===============================================---*
    const fetchMyData = () => {
      fetchingData.value = true
      store.dispatch('client_dashboard/getClientData')
        .then(response => {
          // console.log('some response message', response.data.response.data[0])
          store.commit('client_dashboard/SET_CLIENT_INFO', response.data.response.data[0])
          fetchingData.value = false
        })
        .catch(() => {
          // console.log('some error message', er)
          fetchingData.value = false
        })
    }
    fetchMyData()

    const viewCaregiver = id => {
      store.commit('client_dashboard/SET_CURRENT_CAREGIVER', id)
    }

    return {
      pageTitle: 'Client Dashboard',
      url: Vue.prototype.$mainUrl,
      uDATA,
      clientInfo,
      fetchMyData,
      swiperOptions,
      // UI
      avatarText,
      viewCaregiver,
      // isSideBarOpen,
      // toggleSidebar,
      fetchingData,
    }
  },
  methods: {
    goToCalendar() {
      this.$router.push('/client-shift-calendar')
    },
    goToEditProfile() {
      this.$router.push('/clientdashboard/profile-edit')
    },
  },
  unmounted() {
    this.style.remove()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/swiper.scss';

@import '~@core/scss/base/bootstrap-extended/include';

@media (min-width: (map-get($grid-breakpoints, lg))) {
  .cdashwidget {
    th {
      width: 40%;
    }
  }
}
</style>

<style>
tr {
    border-bottom: none;
}
h1{
  margin: auto;
}
.grad {
  background-color: #329D9C;
  background-image: linear-gradient(to right, #B8D240 , #008483);
}
.card-profile{
  overflow: hidden;
}
.card-profile > img {
   visibility: hidden;
   height: 115px;
}
.card-profile::before {
    content: "";
    width: 100%;
    background-image: linear-gradient(to right, #B8D240 , #008483);
    height:130px;
    position:absolute;
}

.my-caregivers{
    width: 35px!important;
    height: 35px!important;
}
.color-1{
    color:#33475B!important;
}
.color-2{
    color:#516F90!important;
}
.color-3{
  color: #329D9C !important;
}
.text-14{
    font-size: 14px!important;
}
.text-12{
    font-size: 12px!important;
}
.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: .1;
  margin: 0 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  background: #00000069;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  background-color: #fff;
  opacity: 1;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 25px;
}

.upcomingschedlist .media-body{
  display: flex;
}

.upcomingschedlist .list-group-item{
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
  padding: 0.75rem 1.25rem;
}

.upcomingschedlist .list-group-item:hover {
    background-color: #ffffff;
}

.openshift_badge {
  background-color: rgb(231,54,132, 0.15);
  color: #E73684;
}
.scheduled_badge {
  background-color: #01BCD61F !important;
  color: #01BCD6 !important;
}

.textual{
  color:#33475B;
  font-weight: bold;
}

.textual_time{
  color:#98A1AB;
}

[dir] .badge {
  height: 20px;
}
.upcomingschedlist .media-aside{
  align-items: center;
  column-gap: 10px;
}
</style>

<template>
  <div id="caregiver-profile-slider">
    <b-sidebar
      id="sidebar-view-profile-caregiver"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template>
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
            "
        >
          <h5 class="mb-0 font-weight-bolder text-14">
            Caregiver Profile
          </h5>
        </div>

        <div
          class="mt-2 text-center"
        >
          <b-avatar
            rounded
            variant="primary"
            size="100"
            :src="`${url}upload/${currentCaregiver.id}/${currentCaregiver.photo}`"
            :text="currentCaregiver.name ? avatarText(currentCaregiver.name) : '--'"
          />
          <h3
            class="mt-2 color-1"
          >
            {{ currentCaregiver.name || '--' }}
          </h3>
        </div>

        <div
          class="px-2 mt-3"
        >
          <table
            v-for="row in currentCaregiver.infoList"
            :key="row.title"
            class="text-left ml-1"
          >
            <tr>
              <th
                class="pb-50 align-text-top"
              >
                <span class="font-weight-bolder color-1">{{ row.title }}:</span>
              </th>
              <td class="pb-50 pl-1 color-1">
                {{ row.value || '--' }}
              </td>
            </tr>
          </table>
          <hr>
          <h5
            class="font-weight-normal text-uppercase mt-2 mb-2 color-1"
          >
            Professional Experience
          </h5>
          <table
            v-for="row in currentCaregiver.profExp"
            :key="row.title"
            class="text-left ml-1"
          >
            <tr>
              <th
                class="pb-50 align-text-top"
              >
                <span class="font-weight-bolder color-1">{{ row.title }}:</span>
              </th>
              <td class="pb-50 text-capitalize pl-1 color-1">
                {{ row.value || '--' }}
              </td>
            </tr>
          </table>
          <h6
            class="font-weight-bolder ml-1 color-1"
          >
            Employment History
          </h6>
          <app-timeline class="ml-2 pt-1">
            <template v-for="(row, index) in currentCaregiver.history">
              <app-timeline-item
                v-if="row.company != ''"
                :key="index"
                variant="secondary"
              >
                <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
                  <h6 class="color-1">
                    {{ row.company }}
                  </h6>
                </div>
                <p class="text-sm-left m-0 text-muted"><small>{{ row.position }}<br>
                  {{ row.from }} - {{ row.to }}</small></p>
              </app-timeline-item>
            </template>
          </app-timeline>
          <hr>
          <h5
            class="font-weight-normal text-uppercase mt-2 mb-2 color-1"
          >
            CREDENTIALS/CERTIFICATIONS
          </h5>
          <table
            v-for="row in currentCaregiver.creds"
            :key="row.title"
            class="text-left ml-1"
          >
            <tr>
              <th
                class="pb-50 align-text-top"
              >
                <span class="font-weight-bolder color-1">{{ row.title }}:</span>
              </th>
              <td class="pb-50 text-capitalize pl-1 color-1">
                {{ row.value || '--' }}
              </td>
            </tr>
          </table>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            v-b-toggle.sidebar-view-profile-caregiver
            variant="primary"
            class="px-3 my-3"
          >
            OK
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import { /* toRefs */ ref, onUnmounted, computed } from '@vue/composition-api'
import store from '@/store'
import {
  BSidebar,
  BAvatar,
  // BForm,
  // BFormGroup,
  // BFormInput,
  // BInputGroup,
  // BInputGroupPrepend,
  BButton,
  // BFormInvalidFeedback,
  // BRow,
  // BCol,
  // BFormFile,
  // BListGroup,
  // BListGroupItem,
  // BLink,
  // BTooltip,
  VBToggle,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { avatarText } from '@core/utils/filter'
import clientDashboardStoreModule from './clientDashboardStoreModule.js'
// import { toRef } from 'vue'
//   import vSelect from 'vue-select'
//   import flatPickr from 'vue-flatpickr-component'
//   import Ripple from 'vue-ripple-directive'
//   import { ValidationProvider, ValidationObserver } from 'vee-validate'
//   import { required, email, url } from '@validations'
//   import formValidation from '@core/comp-functions/forms/form-validation'
//   import { mask } from 'vue-the-mask'
//   import useCaregiverHandler from './useCaregiverHandler'

export default {
  components: {
    BButton,
    BSidebar,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    // BRow,
    // BCol,
  },
  /* model: {
    prop: 'isSideBarOpen',
    // event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isSideBarOpen: {
      type: Boolean,
      required: true,
    },
  }, */
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const CLIENT_DASHBOARD_APP_STORE_MODULE_NAME = 'client_dashboard'

    // Register module
    if (!store.hasModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)) {
      store.registerModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME, clientDashboardStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(CLIENT_DASHBOARD_APP_STORE_MODULE_NAME)
      }
    })

    const currentCaregiver = ref(computed(() => {
      let index = false
      if (store.state.client_dashboard.currentCaregiver !== false) {
        index = store.state.client_dashboard.clientInfo.active_caregivers.findIndex(x => x.id === store.state.client_dashboard.currentCaregiver)
        const caregiver = store.state.client_dashboard.clientInfo.active_caregivers[index]
        return {
          id: caregiver.id,
          name: caregiver.name,
          photo: caregiver.profilepicture,
          infoList: [
            { title: 'Nickname', value: caregiver.nickname },
            { title: 'Age', value: caregiver.age },
            { title: 'Gender', value: caregiver.gender },
            { title: 'Address', value: caregiver.address },
            { title: 'Home Phone', value: caregiver.phonenumber },
            { title: 'Cellphone', value: caregiver.mobilenumber },
            { title: 'Email Address', value: caregiver.emailaddress },
            { title: 'Languages', value: caregiver.languages },
          ],
          profExp: [
            { title: '# of Years with Current Employer', value: caregiver.yearswithcurrentemployer },
            { title: '# of Years in this Industry', value: caregiver.yearsinindustry },
          ],
          creds: [
            { title: 'HCA License No.', value: caregiver.license.licenseNoHCA },
            { title: 'HHA License No.', value: caregiver.license.licenseNoHHA },
            { title: 'CNA License No.', value: caregiver.license.licenseNoCNA },
            { title: 'RN/VLN License No.', value: caregiver.license.licenseNoRNLVN },
          ],
          history: caregiver.employment_history,
        }
      }

      return {
        id: 0,
        name: '',
        photo: '',
        infoList: [
          { title: 'Nickname', value: '' },
          { title: 'Age', value: '' },
          { title: 'Gender', value: '' },
          { title: 'Address', value: '' },
          { title: 'Home Phone', value: '' },
          { title: 'Cellphone', value: '' },
          { title: 'Email Address', value: '' },
          { title: 'Languages', value: '' },
        ],
        profExp: [
          { title: '# of Years with Current Employer', value: '' },
          { title: '# of Years in this Industry', value: '' },
        ],
        creds: [
          { title: 'HCA License No.', value: '' },
          { title: 'HHA License No.', value: '' },
          { title: 'CNA License No.', value: '' },
          { title: 'RN/VLN License No.', value: '' },
        ],
      }
    }))

    /* const infoList = ref([
      { title: 'Nickname', value: 'Kate' },
      { title: 'Age', value: 30 },
      { title: 'Gender', value: 'Female' },
      { title: 'Address', value: '791 Leisure Lane, Santa Barbara, CA 93101' },
      { title: 'Home Phone', value: 'None' },
      { title: 'Cellphone', value: '(626) 336-2409' },
      { title: 'Email Address', value: 'l.norton@circleofcare.com' },
      { title: 'Languages', value: 'English, Spanish, Filipino, Hangul' },
    ])

    const profExp = ref([
      { title: '# of Years with Current Employer', value: 'None' },
      { title: '# of Years in this Industry', value: 'None' },
    ])

    const creds = ref([
      { title: 'HCA License No.', value: '000-000-0000-000' },
      { title: 'HHA License No.', value: 'None' },
      { title: 'CNA License No.', value: '000-000-0000-000' },
      { title: 'RN/VLN License No.', value: '000-000-0000-000' },
    ]) */

    return {
      // isSideBarOpen,
      url: process.env.VUE_APP_BASE_URL,
      currentCaregiver,
      avatarText,
      // infoList,
      // profExp,
      // creds,
    }
  },
}
</script>

<style scoped>
/* #caregiver-profile-slider .color-1{
  color: #33475B !important;
}

#caregiver-profile-slider .text-muted{
  color: #98A1AB !important;
} */

#caregiver-profile-slider >>> .timeline-item-point::before{
  background-color: #82868b !important;
}

.col-2 {
  margin-top: 30px;
}
</style>
